<template>
  <div class="evaluation"  v-loading="submitLoading" :style="submitLoading ? 'overflow-y:hidden;' : 'overflow-y: scroll;'" element-loading-text="数据提交中" v-if="questionList && questionList[sort - 1]">
    <div class="progress">
      <el-row :gutter="10">
        <el-col :span="20"
          ><el-progress
            v-if="percentage"
            :percentage="percentage"
            :show-text="false"
            style="position: relative; top: 0.5rem"
            color="#30465D"
          ></el-progress
        ></el-col>
        <el-col :span="4">
          <p class="sortBox">
            <span class="sort">{{ sort }}</span
            ><span class="quesLen">/{{ questionList.length }}</span>
          </p>
        </el-col>
      </el-row>
    </div>
    <div style="height:5rem;"></div>
    <div class="main">
      <div class="scrollBox" v-for="(module, m) in questionList[sort - 1].moduleArray" :key="m">
        <div class="moduleDesc" v-if="module.moduleDesc"> {{ module.moduleDesc }} </div>
        <div class="moduleQues" v-for="(item, index) in module.questionArray" :key="index" >
            <div v-if="item.display == 1">
            <b class="quesInfo"> <span v-if="module.questionArray.length > 1" >{{ index + 1 }}.</span >&nbsp;{{ item.quesInfo }}</b >
            <div class="ladder" v-if="item.quesInfo.includes('梯子')">
              <img src="../assets/image/baicheng/ladder.jpg" alt="" />
            </div>
            <!-- 单选 -->
            <div class="radio" v-if=" (item.answerType == 'A' || item.answerType == 'A2') && item.quesInfo.includes('性别') == false" >
              <ul>
                <li v-for="(each, idx) in item.optionArr" :key="idx">
                  <div @click=" groupRadioCheck( item, each)" :class="each.optionId == item.answer ? 'item active' : 'item'" >
                    <p>{{ each.optionInfo }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 跳转题 -->
            <div class="radio" v-if="item.answerType == 'AT'" >
              <ul>
                <li v-for="(each, idx) in item.optionArr" :key="idx">
                  <div @click="groupTurnCheck( item, each, module )" :class="each.optionId == item.answer ? 'item active' : 'item'" >
                    <p>{{ each.optionInfo }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 性别 -->
            <div
              class="gender"
              v-if="item.answerType == 'A' && item.quesInfo.includes('性别')"
            >
              <ul>
                <li
                  class="sex"
                  v-for="(each, idx) in item.optionArr"
                  :class="each.optionId == item.answer ? 'active' : ''"
                  :key="idx"
                  @click=" groupRadioCheck(item, each)" >
                  <img
                    v-if="each.optionInfo == '男' && each.optionId == item.answer"
                    src="../assets/image/baicheng/sex_man_active.png"
                    alt=""
                  />
                  <img
                    v-if="each.optionInfo == '男' && each.optionId != item.answer"
                    src="../assets/image/baicheng/sex_man.png"
                    alt=""
                  />
                  <img
                    v-if="each.optionInfo == '女' && each.optionId == item.answer"
                    src="../assets/image/baicheng/sex_woman_active.png"
                    alt=""
                  />
                  <img
                    v-if="each.optionInfo == '女' && each.optionId != item.answer"
                    src="../assets/image/baicheng/sex_woman.png"
                    alt=""
                  />
                  <span>{{ each.optionInfo }}</span>
                </li>
              </ul>
            </div>
            <!-- 圆圈评分题 -->
            <div class="score" v-if="item.answerType == 'A1'">
              <ul>
                <li
                  v-for="(each, idx) in item.optionArr"
                  :class="each.optionId == item.answer ? 'active' : ''"
                  :key="idx"
                  @click="groupRadioCheck(item,each)" >
                  <span>{{ each.optionInfo }}</span>
                </li>
              </ul>
            </div>
            <!-- 输入框 -->
            <div class="inputBox" v-if="item.answerType == 'B'">
              <!-- 年龄 -->
              <el-input
                v-if="item.quesInfo.includes('年龄')"
                style="width: 80%"
                min="0"
                max="100"
                v-model="item.answer"
                type="number"
                placeholder=""
              ></el-input>
              <el-input
                v-else-if="item.quesInfo.includes('身高')"
                style="width: 80%"
                min="135"
                max="245" @input="inputCheck($event,item,245)" @blur="blurCheck(item,135)"
                v-model="item.answer"
                type="number"
                placeholder=""
              ></el-input>
              <el-input
                v-else-if="item.quesInfo.includes('体重')"
                style="width: 80%"
                min="30"
                max="140" @input="inputCheck($event,item,140)" @blur="blurCheck(item,30)"
                v-model="item.answer"
                type="number"
                placeholder=""
              ></el-input>
              <el-input
                v-else-if="item.quesInfo.includes('担任班主任的班级')"
                style="width: 80%"
                min="1"
                max="100"
                v-model="item.answer"
                type="number"
                placeholder=""
              ></el-input>
              <el-input
                  v-else
                  v-model="item.answer"
                  type="text"
                  placeholder=""
                ></el-input>
            </div>
            <!-- 多选 -->
            <div class="checkbox" v-if="item.answerType == 'C'">
              <ul>
                <li
                  v-for="(each, idx) in item.optionArr"
                  :class="each.checked ? 'active' : ''"
                  :key="idx"
                >
                  <p>
                    <el-checkbox
                      v-model="each.checked"
                      @change="checkboxCheck($event, item, each)"
                      >{{ each.optionInfo }}</el-checkbox
                    >
                  </p>
                </li>
              </ul>
            </div>
            <!-- 选择框 -->
            <div class="selectBox" v-if="item.answerType == 'B1'">
              <el-select
                v-model="item.hour"
                @change="getHour($event, item)"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in hourOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option> </el-select
              ><span>小时</span>
              <el-select
                v-model="item.minute"
                @change="getMinute($event, item)"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in minOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option> </el-select
              ><span>分钟</span>
            </div>
            <div class="imgBox" v-if="item.answerType == 'A3'">
              <ul>
                <li
                  v-for="(each, idx) in item.optionArr"
                  :class="each.optionId == item.answer ? 'active' : ''"
                  :key="idx"
                  @click="groupRadioCheck(item, each)"
                >
                  <img :src="each.optionInfo" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btns">
        <span class="prev" @click="prev" v-if="sort > 1">上一题</span>
        <span class="next" @click="next" v-if="sort != questionList.length"
          >下一题</span
        >
        <span v-if="sort == questionList.length" class="submit" @click="submit"
          >提交</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'mint-ui';
import { getQuestionObjectiveArray, saveObjectiveAns } from '@/api/index';
export default {
  data() {
    return {
      submitLoading:false,
      questionList: [],
      sort: 1,
      answerList: [],
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      hourOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      minOptions: [0, 10, 20, 30, 40, 50]
    };
  },
  computed: {
    percentage() {
      return parseInt((this.sort / this.questionList.length) * 100);
    },
  },
  methods: {
    //最大值校验
    inputCheck(val,item,max) {
      if (val > max) {
        item.answer = max;
      }
    },
    //最小值校验
    blurCheck(item,min) {
      if (item.answer < min) {
        item.answer = min;
      }
    },
    //上一题
    prev() {
      if (this.sort <= 1) {
        return false;
      }
      this.sort--;
      document.querySelector('.evaluation').scrollTop = 0;
    },
    //下一题
    next() {
      if (this.sort >= this.questionList.length) {
        return false;
      }
      let noAnswerLen = 0;
      this.questionList[this.sort - 1].moduleArray.forEach(item => {
        noAnswerLen += item.questionArray.filter(
          (each) => ( each.answer == null || each.answer == '') && each.display == '1'
        ).length
      });
      if (noAnswerLen > 0) {
        Toast('请完成当前问卷！');
      } else {
        this.sort++;
        document.querySelector('.evaluation').scrollTop = 0;
      }
    },
    getHour(val, item) {
      if (!item.minute) {
        item.minute = 0;
      }
      item.hour = val;
      this.$forceUpdate();
      item.answer = item.hour + '小时' + item.minute + '分钟';
    },
    getMinute(val, item) {
      if (!item.hour) {
        item.hour = 0;
      }
      item.minute = val;
      this.$forceUpdate();
      item.answer = item.hour + '小时' + item.minute + '分钟';
    },
    //获取问题
    getQuestion() {
      getQuestionObjectiveArray()
        .then((res) => {
          if (res) {
            this.questionList = res;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //单选
    groupRadioCheck(item, each) {
      item.answer = each.optionId;
      item.optionId = each.optionId;
      item.optionScore = each.optionScore;
      this.$forceUpdate();
    },
    groupTurnCheck(item, each,module) {
      item.answer = each.optionId;
      item.optionId = each.optionId;
      item.optionScore = each.optionScore;
      module.questionArray.forEach(item => {
        if(each.optionId == 16) {
          if(item.fmTeaQuesObjectiveId == 10) {
            item.display = '1';
          }
        } else {
          if(item.fmTeaQuesObjectiveId == 10) {
            item.display = '0';
          }
        }
        if(each.optionId == 17 || each.optionId == 18) {
          if(item.fmTeaQuesObjectiveId == 8 || item.fmTeaQuesObjectiveId == 9) {
            item.display = '1';
          }
        } else {
          if(item.fmTeaQuesObjectiveId == 8 || item.fmTeaQuesObjectiveId == 9) {
            item.display = '0';
          }
        }
        if(each.optionId == 45) {
          if(item.fmTeaQuesObjectiveId == 15 || item.fmTeaQuesObjectiveId == 16) {
            item.display = '1';
          }
        } else {
          if(item.fmTeaQuesObjectiveId == 15 || item.fmTeaQuesObjectiveId == 16) {
            item.display = '0';
          }
        }
      });
      this.$forceUpdate();
    },
    //多选
    checkboxCheck(event, item, each) {
      let mutexArr = ['无人照顾', '以上都没有', '没有参加'];
      if (each.checked && mutexArr.includes(each.optionInfo)) {
        item.optionArr.forEach((option) => {
          if (option.optionInfo != each.optionInfo) {
            option.checked = false;
          }
        });
        this.$forceUpdate();
      } else if (
        each.checked &&
        !mutexArr.includes(each.optionInfo) &&
        mutexArr.includes(
          item.optionArr[item.optionArr.length - 1].optionInfo
        ) &&
        item.optionArr[item.optionArr.length - 1].checked
      ) {
        item.optionArr[item.optionArr.length - 1].checked = false;
        this.$forceUpdate();
      } else {
        this.$forceUpdate();
      }
      item.answerArr = [];
      item.optionIdArr = [];
      item.optionScoreArr = [];
      item.optionArr.forEach((option) => {
        if (option.checked) {
          item.answerArr.push(option.optionId);
          item.optionIdArr.push(option.optionId);
          item.optionScoreArr.push(option.optionScore);
        }
      });
      item.answer = item.answerArr.join(',');
      item.optionId = item.optionIdArr.join(',');
      item.optionScore = item.optionScoreArr.join(',');
    },
    // 提交
    submit() {
      let noAnswerLen = 0;
      this.questionList[this.sort - 1].moduleArray.forEach(item => {
        noAnswerLen += item.questionArray.filter(
          (each) => ( each.answer == null || each.answer == '') && each.display == '1'
        ).length
      });
      if (noAnswerLen > 0) {
        Toast('请完成当前问卷！');
        return false;
      }
      this.submitLoading = true;
      this.endTime = this.$util.getTimestamp();
      this.answerList = [];
      this.questionList.forEach((list) => {
        list.moduleArray.forEach((item) => {
          item.questionArray.forEach((each) => {
            if (each.answer) {
              this.answerList.push({
                fmTeaQuesObjectiveId: each.fmTeaQuesObjectiveId,
                moduleId: each.moduleId,
                answerType: each.answerType,
                answer: each.answer,
                optionId: each.optionId !== '' ? each.optionId : '',
                optionScore: each.optionScore !== ''  ? each.optionScore : '',
              });
            }
          })
        })
      })

      let params = {
        fmTeaUserId: this.$util.getStorage('fmTeaUserId'), //用户ID
        totalTime: this.endTime - this.startTime,
        myAns: this.answerList,
        quesNum:'0-2'
      };
      saveObjectiveAns(params)
        .then((res) => {
          if (res) {
            this.submitLoading = false;
            if(res.data.isFinished == 0) {
              if(res.data.quesNum == '0-1') {
                this.$router.replace({
                  path: '/mQues'
                });
              }
            }
            if(res.data.isFinished == 1) {
              this.$router.replace({
                path: '/mFinish'
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.submitLoading = false;
        });
    },
  },
  created() {
    document.title = '积极心理品质测试';
    this.getQuestion();
  },
  mounted() {
    this.startTime = this.$util.getTimestamp();
  },
};
</script>

<style lang="scss" scoped>
::v-deep input[type='number'] {
  border-radius: 1.3rem;
  text-align: center;
}
.evaluation {
  width: 100vw;
  height: 100%;
  background: #ffffff;
  font-size: 0.95rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;
  .progress {
    width: 100%;
    height: 3.5rem;
    padding: 1rem;
    background: #ffffff;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    .sortBox {
      text-align: center;
      .sort {
        color: #30465D;
        font-size: 1.3rem;
      }
      .quesLen {
        font-size: 0.9rem;
      }
    }
  }
  .main {
    width: 90%;
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 1rem;
    border-radius: 0.3rem;
    .moduleName {
      font-size: 1rem;
      font-weight: 550;
      line-height: 1.2rem;
    }
    .moduleDesc {
      // color: #666666;
      line-height: 1.4rem;
      font-size: 1rem;
      font-weight: 550;
      margin-bottom: 1.8rem;
    }
    .moduleQues {
      margin-bottom: 2rem;
      .quesInfo {
        width: 100%;
        color: #000000;
        display: inline-block;
        line-height: 1.2rem;
        font-size: 0.95rem;
        font-weight: 400;
        margin-bottom: 1.25rem;
      }
      .ladder {
        text-align: center;
        img {
          width: 85%;
        }
      }
      .score {
        li {
          display: inline-block;
          width: 4rem;
          min-height: 2.6rem;
          padding: 0.65rem 0;
          line-height: 1.3rem;
          text-align: center;
          vertical-align: top;
          color: #30465D;
          background: #E8F2F6;
          border-radius: 0.6rem;
          text-align: center;
          margin-right: 1.25rem;
          margin-bottom: 1.25rem;
        }
        li:nth-child(4n + 4) {
          margin-right: 0;
        }
        .active {
          color: #ffffff;
          background: #30465D;
          p {
            color: #30465D;
          }
        }
      }
      .radio {
        font-size: 0.95rem;
        li {
          width: 19.5rem;
          margin: 0 auto 0.6rem auto;
          .item {
            min-height: 2.6rem;
            padding: 0.65rem;
            line-height: 1.3rem;
            margin-bottom: 0.5rem;
            color: #30465D;
            background: #E8F2F6;
            cursor: pointer;
            text-align: center;
            border-radius: 1.3rem;
          }
          .active {
            color: #ffffff;
            background: #30465D;
          }
        }
      }
      .checkbox {
        font-size: 0.95rem;
        li {
          width: 19.5rem;
          min-height: 2.6rem;
          padding: 0.65rem;
          line-height: 1.3rem;
          border-radius: 1.3rem;
          margin: 0 auto 0.6rem auto;
          color: #30465D;
          background: #E8F2F6;
          cursor: pointer;
          text-align: center;
          p {
            .optionInfo {
              width: 15rem;
              display: inline-block;
            }
          }
        }
        ::v-deep .el-checkbox__label {
          width: 16rem;
          color: #30465D;
          display: inline-grid;
          white-space: pre-line;
          word-wrap: break-word;
          overflow: hidden;
          line-height: 1.3rem;
        }
        .active {
          color: #ffffff;
          background: #30465D;
          ::v-deep .el-checkbox__label {
            color: #ffffff;
          }
        }
      }
      .gender {
        text-align: center;
        .sex {
          width: 8rem;
          height: 2.6rem;
          line-height: 2.6rem;
          color: #30465D;
          background: #E8F2F6;
          border-radius: 2.8rem;
          display: inline-block;
          text-align: center;
          font-size: 0.9rem;
          cursor: pointer;
          margin: 0 0.6rem;
          img {
            width: 1.5rem;
            margin-top: 0.6rem;
            margin-right: 0.5rem;
          }
        }
        .active {
          color: #ffffff;
          border-radius: 1.5rem;
          background: #30465D;
        }
      }
      .slider {
        width: 18rem;
        margin-left: 0.5rem;
        line-height: 2rem;
        .minute {
          display: inline-block;
          line-height: 1rem;
          color: #30465D;
          font-size: 0.8rem;
        }
      }
      .inputBox {
        // margin: 1.5rem 0;
        .unit {
          display: inline-block;
          margin-left: 0.7rem;
          font-size: 0.9rem;
        }
      }
      .selectBox {
        ::v-deep .el-select {
          width: 6.5rem;
        }
        ::v-deep .el-select .el-input__inner {
          border-radius: 1.3rem;
        }
        span {
          color: #666666;
          display: inline-block;
          font-size: 0.8rem;
          margin-right: 1rem;
          margin-left: 0.5rem;
        }
      }
      .imgBox {
        ul {
          text-align: center;
          li {
            width: 19.6rem;
            height: 9rem;
            background: #f7f9f7;
            margin-bottom: 0.6rem;
            display: inline-block;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .active {
            background: #dee8de;
          }
        }
      }
    }
  }
  .bottom {
    width: 90%;
    margin: 0 auto 3rem auto;
    overflow: hidden;
    text-align: center;
    .btns {
      color: #289257;
      font-size: 0.95rem;
      display: inline-block;
      .prev,
      .next,
      .submit {
        width: 9rem;
        height: 2.6rem;
        line-height: 2.6rem;
        border-radius: 1.3rem;
        display: inline-block;
      }
      .prev {
        color: #30465D;
        background: #E8F2F6;
        margin-right: 1.8rem;
      }
      .next,
      .submit {
        color: #ffffff;
        background: #30465D;
      }
    }
  }
}
</style>
